import { useRouter } from 'next/router'

import React from 'react'

import en from '../locales/en.json'
import es from '../locales/es.json'
import de from '../locales/de.json'

const translations = {
  en,
  es,
  de,
}

export function useTranslation() {
  const { locale } = useRouter()

  const t = (key: unknown) => {
    if (typeof key !== 'string' && !React.isValidElement(key)) return ''

    return translations[locale]?.[key] || translations.en?.[key as string] || key
  }

  const replacePlaceholders = (text: string, variables: Record<string, any>) => {
    return text.replace(/{([^}]+)}/g, (match, key) => {
      return key in variables ? variables[key] : match
    })
  }

  return { t, locale, replacePlaceholders }
}
