import { useRouter } from 'next/router'

export default function useLocaleSwitch() {
  const router = useRouter()
  const { pathname, query, asPath, locale } = router

  function switchLocale(newLocale) {
    if (newLocale === locale) return

    router.push({ pathname, query }, asPath, { locale: newLocale })
  }

  return { switchLocale, currentLocale: locale }
}
