import { ReactSVG } from "react-svg"
import cn from 'classnames'

import IconArrow from '../../icons/icon-arrow.svg'
import IconLocale from '../../icons/icon-i18n.svg'
import IconCheck from '../../icons/icon-check.svg'
import IconChevron from '../../icons/chevron-right.svg'

import styles from './styles.module.scss'
import useLocaleSwitch from "utils/use-locale-switch"
import useViewport from "libs/use-viewport"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import Popup from "components/ui/popup"
import { useRouter } from "next/router"
import { useTranslation } from "utils/use-translation"

const LOCALES = { 'en': 'English', 'es': 'Español', 'de': 'Deutsch' }

interface Props {
    className?: string
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

const LocaleButton: React.FC<Props> = ({ isOpen, setIsOpen, className }) => {
    const { t } = useTranslation()
    const router = useRouter()
    const [view] = useViewport()

    const [isAccountPage, setIsAccountPage] = useState<boolean>(true)
    const [isDarkTheme, setIsDarkTheme] = useState(false)
    const { switchLocale, currentLocale } = useLocaleSwitch()

    const isMobile = ['mobile', 'xs-mobile', 'xs-tablet'].includes(view)

    const renderLocaleList = () => (Object.entries(LOCALES).map(([key, value], id) => (
        <li key={id} onClick={() => switchLocale(key)}>
            <span>{value}</span>
            {key === currentLocale && <ReactSVG src={IconCheck} className={styles.checkIcon} />}
        </li>
    )))

    const handleClick = () => {
        if (isMobile) {
            setIsOpen(true)
        }
    }

    useEffect(() => {
        setIsAccountPage(router.pathname.includes('account'))
        setIsDarkTheme(router.pathname.includes('private-clients'))
    }, [router])

    return (
        <>
            <button
                onClick={handleClick}
                className={cn(styles.i18n,
                    isAccountPage && styles.account,
                    !isAccountPage && styles.main,
                    isDarkTheme && styles.dark,
                    className)}>
                <ReactSVG src={IconLocale} className={styles.localeIcon} />
                {isMobile && !isAccountPage && <span>{LOCALES[currentLocale]}</span>}
                {isMobile
                    ? !isAccountPage
                        ? <ReactSVG src={IconChevron} className={styles.chevronIcon} />
                        : <ReactSVG src={IconArrow} className={styles.arrowIcon} />
                    : <ReactSVG src={IconArrow} className={styles.arrowIcon} />}

                <ul className={styles.i18nGroup}>
                    {renderLocaleList()}
                </ul>
            </button>

            {isOpen && isAccountPage &&
                <Popup
                    className={styles.i18nPopup}
                    onClose={() => setIsOpen(false)}
                    heading={t('choose_language')}>
                    {renderLocaleList()}
                </Popup>
            }

            {!isAccountPage && 
                <div className={cn(styles.i18nPopupMain, isOpen && styles.open)}>
                    <button 
                        className={styles.i18nPopupMainHeader} 
                        onClick={() => setIsOpen(false)}>
                        <ReactSVG src={IconChevron} className={styles.chevronIcon} />
                        <ReactSVG src={IconLocale} className={styles.localeIcon} />
                        <p>{t('choose_language')}</p>
                    </button>
                    {renderLocaleList()}
                </div>
            }
        </>
    )
}

export default LocaleButton