export function formatNumberWithSpaces(number: number) {
  const numberString = String(number)

  const [integerPart, decimalPart] = numberString.split('.')

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  const formattedNumber = decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart

  return formattedNumber
}
