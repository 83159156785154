import { useEffect, useState } from 'react'
import Link from 'next/link'
import cn from 'classnames'
import LogoInverted from 'components/ui/logotype/logotype-inverted.svg'

import { useRouter } from 'next/router'

import { ReactSVG } from 'react-svg'

import { useTranslation } from 'utils/use-translation'

import LogoDark from './icons/logo_dark.svg'

import IconTwitter from './icons/twitter.svg'
import IconTelegram from './icons/telegram.svg'
import IconLinkedIn from './icons/linkedin.svg'
import IconYoutube from './icons/youtube.svg'

import styles from './styles.module.scss'

export default function Footer() {
  const { t } = useTranslation()

  const [windowWidth, setWindowWidth] = useState(0)
  const [isDark, setIsDark] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', (event) => {
      setWindowWidth(event.currentTarget.innerWidth)
    })
  }, [])

  useEffect(() => {
    if (router) {
      setIsDark(router.pathname.includes('private-clients') || router.pathname.includes('Consensus2025_main'))
    }
  }, [router])

  return (
    <div className={cn(styles.footer, { [styles.footer_vip]: isDark })}>
      <div className={cn('container', styles.footerContainer)}>
        <div className={styles.legalInfo}>
          <div className={styles.copyright}>
            <Link prefetch={false} href="/">
              <a>
                <img src={isDark ? LogoDark : LogoInverted} alt="CoinRabbit" width={123} height={25} loading="lazy" />
              </a>
            </Link>
            <p className={styles.copyrightText}>{new Date().getFullYear()} © Coinrabbit</p>
          </div>
          <div className={styles.legalEntity}>
            Rabbit Finance LLC, <span> </span>
            <br className={styles.brToHide} />
            Richmond Hill Rd, Kingstown,
            <br />
            St. Vincent and the Grenadines, <span> </span>
            <br className={styles.brToHide} />
            VC0100
            <br />
          </div>
        </div>
        <div className={styles.rightBlock}>
          {(windowWidth < 655 && (
            <div className={styles.column}>
              <Link prefetch={false} href="/assets" passHref>
                <a>
                  <b>{t('crypto_assets')}</b>
                </a>
              </Link>

              <div className={styles.innerColumn}>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/btc">
                    <a>{t('bitcoin_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/eth">
                    <a>{t('ethereum_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bch">
                    <a>{t('bitcoin_cash_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xno">
                    <a>{t('nano_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/doge">
                    <a>{t('dogecoin_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xrp">
                    <a>{t('xrp_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/firo">
                    <a>{t('firo_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xmr">
                    <a>{t('monero_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/rvn">
                    <a>{t('ravencoin_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/shib">
                    <a>{t('shiba_inu_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dgb">
                    <a>{t('dgb_loan')}</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/zil">
                    <a>{t('zil_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ada">
                    <a>{t('ada_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/lrc">
                    <a>{t('lrc_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/uni">
                    <a>{t('uni_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/link">
                    <a>{t('link_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/vet">
                    <a>{t('vet_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/sushi">
                    <a>{t('sushi_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ilv">
                    <a>{t('ilv_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zrx">
                    <a>{t('zrx_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/sol">
                    <a>{t('sol_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dash">
                    <a>{t('dash_loan')}</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/mana">
                    <a>{t('mana_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/trx">
                    <a>{t('trx_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bone">
                    <a>{t('bone_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/volt">
                    <a>{t('volt_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/aave">
                    <a>{t('aave_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ftm">
                    <a>{t('ftm_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bnb">
                    <a>{t('bnb_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dot">
                    <a>{t('dot_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ltc">
                    <a>{t('ltc_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/mkr">
                    <a>{t('mkr_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/matic">
                    <a>{t('matic_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zec">
                    <a>{t('zec_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/algo">
                    <a>{t('algo_loan')}</a>
                  </Link>
                </div>
              </div>
            </div>
          )) || (
            <div className={styles.column}>
              <Link prefetch={false} href="/assets" passHref>
                <a>
                  <b>{t('crypto_assets')}</b>
                </a>
              </Link>
              <div className={styles.innerColumn}>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/btc">
                    <a>{t('bitcoin_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/eth">
                    <a>{t('ethereum_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bch">
                    <a>{t('bitcoin_cash_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xno">
                    <a>{t('nano_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/doge">
                    <a>{t('dogecoin_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xrp">
                    <a>{t('xrp_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/firo">
                    <a>{t('firo_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/xmr">
                    <a>{t('monero_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/rvn">
                    <a>{t('ravencoin_loan')}</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/shib">
                    <a>{t('shiba_inu_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dgb">
                    <a>{t('dgb_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zil">
                    <a>{t('zil_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/ada">
                    <a>{t('ada_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/lrc">
                    <a>{t('lrc_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/uni">
                    <a>{t('uni_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/link">
                    <a>{t('link_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/vet">
                    <a>{t('vet_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/jasmy">
                    <a>{t('jasmy_loan')}</a>
                  </Link>
                </div>
                <div className={styles.linksColumn}>
                  <Link prefetch={false} href="/loans/ilv">
                    <a>{t('ilv_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/zrx">
                    <a>{t('zrx_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/sol">
                    <a>{t('sol_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/dash">
                    <a>{t('dash_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/mana">
                    <a>{t('mana_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/trx">
                    <a>{t('trx_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/bone">
                    <a>{t('bone_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/volt">
                    <a>{t('volt_loan')}</a>
                  </Link>
                  <Link prefetch={false} href="/loans/aave">
                    <a>{t('aave_loan')}</a>
                  </Link>
                </div>
              </div>
            </div>
          )}
          <div className={cn(styles.column, styles.loans)}>
            <Link prefetch={false} href="/" passHref>
              <a>
                <b>{t('crypto_loans_capital')}</b>
              </a>
            </Link>
            <Link prefetch={false} href="/loans/usdt">
              <a>{t('usdt_erc20')}</a>
            </Link>
            <Link prefetch={false} href="/loans/usdttrc20">
              <a>{t('usdt_trc20')}</a>
            </Link>

            <Link prefetch={false} href="/wallet" passHref>
              <a>
                <b className={styles.marginLink}>{t('crypto_wallet_capital')}</b>
              </a>
            </Link>
            <Link prefetch={false} href="/wallet-coin/btc">
              <a>{t('btc_wallet')}</a>
            </Link>

            <Link prefetch={false} href="/exchange" passHref>
              <a>
                <b className={styles.marginLink}>{t('crypto_exchange')}</b>
              </a>
            </Link>
            <Link prefetch={false} href="/exchange-pair">
              <a>{t('btc_to_xmr')}</a>
            </Link>
          </div>
          <div className={cn(styles.column, styles.earnings)}>
            <Link prefetch={false} href="/earn" passHref>
              <a>
                <b>{t('earn_interest')}</b>
              </a>
            </Link>
            <Link prefetch={false} href="/earn">
              <a>{t('usdt_erc20')}</a>
            </Link>
            <Link prefetch={false} href="/earn">
              <a>{t('usdt_trc20')}</a>
            </Link>
            <Link prefetch={false} href="/earn">
              <a>{t('usdc')}</a>
            </Link>
          </div>
          <div className={styles.socials}>
            <div className={styles.socialsBox}>
              {/* <a href="mailto:support@coinrabbit.io" title="contact us" aria-label="go-to-mail">
                <img
                  className={styles.iconMail}
                  src={IconMail}
                  alt="Contact Us: support@coinrabbit.io"
                  width={16}
                  height={10}
                  loading="lazy"
                />
              </a>
              <a
                href="https://twitter.com/CoinRabbitLoans"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="twitter"
                aria-label="go-to-twitter-social"
              >
                <img src={IconTwitter} alt="CoinRabbit in twitter" width={16} height={14} loading="lazy" />
              </a>
              <a
                href="https://t.me/+vKoWSNIXLbZmZTQy"
                target="_blank"
                rel="noopener noreferrer nofollow"
                title="telegram"
                aria-label="go-to-telegram-social"
              >
                <img src={IconTelegram} alt="CoinRabbit in telegram" width={18} height={14} loading="lazy" />
              </a> */}

              <a
                className={styles.socialLink}
                href="https://twitter.com/CoinRabbitLoans"
                title="x link"
                aria-label="go-to-x"
                rel="noopener noreferrer"
                target="_blank"
              >
                <ReactSVG className={styles.icon} src={IconTwitter} alt="Twitter icon" width={20} height={20} />
              </a>

              <a
                className={styles.socialLink}
                href="https://t.me/+vKoWSNIXLbZmZTQy"
                title="telegram link"
                aria-label="go-to-telegram"
                rel="noopener noreferrer"
                target="_blank"
              >
                <ReactSVG className={styles.icon} src={IconTelegram} alt="Telegram icon" width={20} height={20} />
              </a>

              <a
                className={styles.socialLink}
                href="https://www.linkedin.com/company/80426861/admin/page-posts/published/"
                title="linkedIn link"
                aria-label="go-to-linkedin"
                rel="noopener noreferrer"
                target="_blank"
              >
                <ReactSVG className={styles.icon} src={IconLinkedIn} alt="LinkedIn icon" width={20} height={20} />
              </a>

              <a
                className={styles.socialLink}
                href="https://www.youtube.com/@coinrabbit_loans"
                title="youtube link"
                aria-label="go-to-youtube"
                rel="noopener noreferrer"
                target="_blank"
              >
                <ReactSVG className={styles.icon} src={IconYoutube} alt="Youtube icon" width={20} height={20} />
              </a>
            </div>
            <div className={styles.privacyPolicy}>
              <Link prefetch={false} href="/partners">
                <a>{t('for_partners')}</a>
              </Link>
              <Link prefetch={false} href="/terms-of-use">
                <a>{t('terms_of_use_capital')}</a>
              </Link>
              <Link prefetch={false} href="/privacy-policy">
                <a>{t('privacy_policy')}</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={cn('container', styles.footerContainer)}>
        <p className={styles.footerText}>{t('liability_notice')}</p>
      </div>
    </div>
  )
}
