import { Request } from 'libs/request'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

export interface TBodyResponse<T = any> {
  result: boolean
  response: T
  message?: string
  code?: number
}

const api = new Request<TBodyResponse>({
  baseURL: publicRuntimeConfig.API_URL,
  headers: {
    'x-api-key': publicRuntimeConfig.API_KEY,
  },
})

export const apiExchanges = new Request<TBodyResponse>({
  // baseURL: 'https://exchange-staging.coinrabbit.io',
  baseURL: 'https://exchange.coinrabbit.io',
  headers: {
    'x-api-key': publicRuntimeConfig.API_KEY,
  },
})

export const apiCMS = new Request<unknown>({
  // baseURL: 'https://cms-staging.coinrabbit.io',
  baseURL: 'https://cms.coinrabbit.io',
  // baseURL: 'http://localhost:1337',
})

export function setApiUserToken(token: string) {
  api.replaceHeaders({
    'x-user-token': token,
  })

  apiExchanges.replaceHeaders({
    'x-user-token': token,
  })
}

export function setApiNoraUserToken(token: string) {
  api.replaceHeaders({
    'x-nora-user-token': token,
  })

  apiExchanges.replaceHeaders({
    'x-nora-user-token': token,
  })
}

export default api
